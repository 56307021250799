<template>
  <div class="text-center">
    <div class="timer">{{ time | prettify }}</div>
  </div>
</template>

<script>
export default {
  props: ['time'],
  name: 'Timer-component',
  computed: {},
  data() {
    return { minutes: 0, secondes: 0 }
  },
  filters: {
    prettify: function (value) {
      let data = value.split(':')
      let minutes = data[0]
      let secondes = data[1]
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (secondes < 10) {
        secondes = '0' + secondes
      }
      return minutes + ':' + secondes
    }
  }
}
</script>
