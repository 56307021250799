var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "root" },
    [
      _vm.examSessionStudent.IsCompleted === true || _vm.time <= 0
        ? _c(
            "div",
            { staticClass: "mt-10", attrs: { align: "center" } },
            [
              _c("b-alert", { attrs: { show: "", variant: "danger" } }, [
                _c("h1", [_vm._v("OTURUMUNUZ BİTMİŞTİR.")]),
              ]),
              _c(
                "div",
                { staticClass: "text-center flex-grow-1" },
                [
                  _vm.time > 0
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", block: "" },
                          on: {
                            click: function ($event) {
                              return _vm.continueExam()
                            },
                          },
                        },
                        [_vm._v("Devam Et")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.examSessionStudent.IsCompleted === false && _vm.time > 0
        ? _c(
            "div",
            { staticClass: "mt-10", attrs: { align: "center" } },
            [
              _c(
                "div",
                { staticClass: "text-right flex-grow-1" },
                [
                  _vm.examSessionSets.length
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mb-2",
                          attrs: { color: "success", block: "" },
                          on: {
                            click: function ($event) {
                              return _vm.endExam()
                            },
                          },
                        },
                        [_vm._v("Sınavı Bitir ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-card-group",
                { attrs: { deck: "" } },
                [
                  _vm.showFile && _vm.examSessionFiles.length
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c("v-switch", {
                                attrs: { label: "DOSYALARI GÖSTER" },
                                model: {
                                  value: _vm.showFile,
                                  callback: function ($$v) {
                                    _vm.showFile = $$v
                                  },
                                  expression: "showFile",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("b-card-title", [_vm._v(" OTURUM DOSYALARI")]),
                          _c(
                            "b-card-text",
                            [
                              _c(
                                "v-tabs",
                                {
                                  attrs: {
                                    "background-color": "indigo",
                                    dark: "",
                                    grow: "",
                                  },
                                  model: {
                                    value: _vm.tab2,
                                    callback: function ($$v) {
                                      _vm.tab2 = $$v
                                    },
                                    expression: "tab2",
                                  },
                                },
                                _vm._l(
                                  _vm.examSessionFiles,
                                  function (item, i) {
                                    return _c("v-tab", { key: i }, [
                                      _vm._v(_vm._s(item.Name)),
                                    ])
                                  }
                                ),
                                1
                              ),
                              _c(
                                "v-tabs-items",
                                {
                                  model: {
                                    value: _vm.tab2,
                                    callback: function ($$v) {
                                      _vm.tab2 = $$v
                                    },
                                    expression: "tab2",
                                  },
                                },
                                _vm._l(_vm.examSessionFiles, function (item) {
                                  return _c(
                                    "v-tab-item",
                                    { key: item.tab2 },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { justify: "center" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "12" } },
                                            [
                                              _c(
                                                "v-card",
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "success",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.downloadFile(
                                                            item.FilePublicUrl
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" Dosyayı İndir "),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            right: "",
                                                            dark: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-download"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("meta", {
                                                    attrs: { charset: "UTF-8" },
                                                  }),
                                                  _c("title", [
                                                    _vm._v("PDF.js Example"),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "container",
                                                    },
                                                    [
                                                      _c("iframe", {
                                                        style: _vm.isMobile
                                                          ? "overflow-y: auto; overflow-x: scroll"
                                                          : "overflow-y: auto; overflow-x: scroll; min-width: 650px",
                                                        attrs: {
                                                          src:
                                                            (item.FilePublicUrl.includes(
                                                              "pdf"
                                                            ) ||
                                                              item.FilePublicUrl.includes(
                                                                "PDF"
                                                              )) &&
                                                            _vm.isMobile
                                                              ? "https://docs.google.com/gview?url=" +
                                                                item.FilePublicUrl +
                                                                "&embedded=true"
                                                              : item.FilePublicUrl,
                                                          width: "auto",
                                                          height: _vm.isMobile
                                                            ? "400"
                                                            : "800",
                                                          frameborder: "0",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.examSessionSets.length
                    ? _c(
                        "b-card",
                        { staticStyle: { "max-width": "360px" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c("v-switch", {
                                attrs: { label: "DOSYALARI GÖSTER" },
                                model: {
                                  value: _vm.showFile,
                                  callback: function ($$v) {
                                    _vm.showFile = $$v
                                  },
                                  expression: "showFile",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("b-form-select", {
                            staticClass: "mb-2 mr-sm-2 mb-sm-0",
                            attrs: {
                              options: _vm.computedExamSessionSets,
                              size: "sm",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.selectExamSessionSet()
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "first",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-form-select-option",
                                        {
                                          attrs: { value: null, disabled: "" },
                                        },
                                        [
                                          _vm._v(
                                            "-- Oturuma Başlamak İçin Lütfen Seçim Yapınız -- "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2502346715
                            ),
                            model: {
                              value: _vm.tab,
                              callback: function ($$v) {
                                _vm.tab = $$v
                              },
                              expression: "tab",
                            },
                          }),
                          _c(
                            "b-overlay",
                            { attrs: { show: _vm.loading, opacity: "0.6" } },
                            [
                              _c(
                                "v-tabs-items",
                                {
                                  model: {
                                    value: _vm.tab,
                                    callback: function ($$v) {
                                      _vm.tab = $$v
                                    },
                                    expression: "tab",
                                  },
                                },
                                _vm._l(_vm.examSessionSets, function (item) {
                                  return _c(
                                    "v-tab-item",
                                    { key: item.tab },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { justify: "center" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "6" } },
                                            [
                                              _c("v-card", {
                                                attrs: { flat: "" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("b-card-title", [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.QuestionCount) +
                                            " soru "
                                        ),
                                        _c("hr"),
                                      ]),
                                      _vm._l(
                                        item.QuestionCount,
                                        function (element, i) {
                                          return _c("div", { key: i }, [
                                            _c(
                                              "div",
                                              { staticClass: "mt-3 d-flex" },
                                              [
                                                _vm.answers.filter((x) => {
                                                  return (
                                                    x.QuestionNumber == i + 1
                                                  )
                                                }).length
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-center flex-grow-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(i + 1) +
                                                            ") "
                                                        ),
                                                        _c(
                                                          "b-form-radio-group",
                                                          {
                                                            attrs: {
                                                              options:
                                                                _vm.examQuestionOptions,
                                                              "button-variant":
                                                                "outline-primary",
                                                              size: "md",
                                                              name: "radio-btn-outline",
                                                              buttons: "",
                                                              "value-field":
                                                                "Id",
                                                              "text-field":
                                                                "Name",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                _vm.ExamSessionSetId =
                                                                  item.Id
                                                                _vm.saveAnswer(
                                                                  i + 1,
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.answers.filter(
                                                                  (x) => {
                                                                    return (
                                                                      x.QuestionNumber ==
                                                                      i + 1
                                                                    )
                                                                  }
                                                                )[0]
                                                                  .ExamQuestionOptionId,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.answers.filter(
                                                                      (x) => {
                                                                        return (
                                                                          x.QuestionNumber ==
                                                                          i + 1
                                                                        )
                                                                      }
                                                                    )[0],
                                                                    "ExamQuestionOptionId",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "\n                        answers.filter((x) => {\n                          return x.QuestionNumber == i + 1\n                        })[0].ExamQuestionOptionId\n                      ",
                                                            },
                                                          }
                                                        ),
                                                        _vm.answers.filter(
                                                          (x) => {
                                                            return (
                                                              x.QuestionNumber ==
                                                              i + 1
                                                            )
                                                          }
                                                        )[0].IsFlagged
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "b-tooltip",
                                                                    rawName:
                                                                      "v-b-tooltip.hover.top",
                                                                    value:
                                                                      "Eminim olarak işaretle",
                                                                    expression:
                                                                      "'Eminim olarak işaretle'",
                                                                    modifiers: {
                                                                      hover: true,
                                                                      top: true,
                                                                    },
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "mx-2",
                                                                attrs: {
                                                                  fab: "",
                                                                  dark: "",
                                                                  "x-small": "",
                                                                  color:
                                                                    "error",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.ExamSessionSetId =
                                                                        item.Id
                                                                      _vm.saveNotSure(
                                                                        i + 1,
                                                                        _vm.answers.filter(
                                                                          (
                                                                            x
                                                                          ) => {
                                                                            return (
                                                                              x.QuestionNumber ==
                                                                              i +
                                                                                1
                                                                            )
                                                                          }
                                                                        )[0]
                                                                          .IsFlagged
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-flag"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "v-btn",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "b-tooltip",
                                                                    rawName:
                                                                      "v-b-tooltip.hover.top",
                                                                    value:
                                                                      "Emin değilim olarak işaretle",
                                                                    expression:
                                                                      "'Emin değilim olarak işaretle'",
                                                                    modifiers: {
                                                                      hover: true,
                                                                      top: true,
                                                                    },
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "mx-2",
                                                                attrs: {
                                                                  fab: "",
                                                                  dark: "",
                                                                  "x-small": "",
                                                                  color:
                                                                    "secondary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.ExamSessionSetId =
                                                                        item.Id
                                                                      _vm.saveNotSure(
                                                                        i + 1,
                                                                        _vm.answers.filter(
                                                                          (
                                                                            x
                                                                          ) => {
                                                                            return (
                                                                              x.QuestionNumber ==
                                                                              i +
                                                                                1
                                                                            )
                                                                          }
                                                                        )[0]
                                                                          .IsFlagged
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-flag-outline"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-center flex-grow-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(i + 1) +
                                                            ") "
                                                        ),
                                                        _c(
                                                          "b-form-radio-group",
                                                          {
                                                            attrs: {
                                                              options:
                                                                _vm.examQuestionOptions,
                                                              "button-variant":
                                                                "outline-primary",
                                                              size: "md",
                                                              name: "radio-btn-outline",
                                                              buttons: "",
                                                              "value-field":
                                                                "Id",
                                                              "text-field":
                                                                "Name",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                _vm.ExamSessionSetId =
                                                                  item.Id
                                                                _vm.saveAnswer(
                                                                  i + 1,
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                          }
                                                        ),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass: "mx-2",
                                                            attrs: {
                                                              fab: "",
                                                              dark: "",
                                                              "x-small": "",
                                                              disabled: "",
                                                            },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-close"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      _c("b-progress", {
                                        staticClass: "mt-3",
                                        attrs: {
                                          max: _vm.progressMax,
                                          "show-value": "",
                                        },
                                      }),
                                    ],
                                    2
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "b-card",
                        [_c("b-card-text", [_vm._v(" OTURUMDA SET YOK")])],
                        1
                      ),
                ],
                1
              ),
              _vm.examSessionStudent != {} && _vm.time > 0
                ? _c(
                    "v-snackbar",
                    {
                      attrs: {
                        timeout: _vm.timeout,
                        color: _vm.snackbarColor,
                        rounded: "pill",
                        top: "",
                      },
                      model: {
                        value: _vm.snackbar,
                        callback: function ($$v) {
                          _vm.snackbar = $$v
                        },
                        expression: "snackbar",
                      },
                    },
                    [_c("Timer", { attrs: { time: _vm.prettyTime } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      !_vm.examSessionStudent
        ? _c(
            "div",
            { staticClass: "mt-10", attrs: { align: "center" } },
            [
              _c("b-alert", { attrs: { show: "", variant: "danger" } }, [
                _c("h1", [_vm._v("OTURUM KAYDINIZ BULUNAMADI")]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "350px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title"),
              _c(
                "v-card-text",
                [
                  _c("v-container", { staticClass: "text-center text-break" }, [
                    _vm.examSessionStudent.IsCompleted
                      ? _c("h2", [
                          _vm._v(
                            " Oturuma devam etmek istediğinize emin misiniz? "
                          ),
                        ])
                      : _vm._e(),
                    !_vm.examSessionStudent.IsCompleted
                      ? _c("h2", [
                          _vm._v(
                            " Oturumu bitirmek istediğinize emin misiniz? "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" HAYIR")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.saveExamSessionStudent()
                        },
                      },
                    },
                    [_vm._v(" EVET")]
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }