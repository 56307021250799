<template>
  <div class="root">
    <div v-if="examSessionStudent.IsCompleted === true || time <= 0" class="mt-10" align="center">
      <b-alert show variant="danger">
        <h1>OTURUMUNUZ BİTMİŞTİR.</h1>
      </b-alert>
      <div class="text-center flex-grow-1">
        <v-btn v-if="time > 0" color="primary" block @click="continueExam()">Devam Et</v-btn>
      </div>
    </div>
    <div v-if="examSessionStudent.IsCompleted === false && time > 0" class="mt-10" align="center">
      <div class="text-right flex-grow-1">
        <v-btn v-if="examSessionSets.length" color="success" class="mb-2" block @click="endExam()"
        >Sınavı Bitir
        </v-btn
        >
      </div>
      <b-card-group deck>
        <b-card v-if="showFile && examSessionFiles.length">
          <v-row justify="center">
            <v-switch v-model="showFile" label="DOSYALARI GÖSTER"></v-switch>
          </v-row>
          <b-card-title> OTURUM DOSYALARI</b-card-title>
          <b-card-text>
            <v-tabs v-model="tab2" background-color="indigo" dark grow>
              <v-tab v-for="(item, i) in examSessionFiles" :key="i">{{ item.Name }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab2">
              <v-tab-item v-for="item in examSessionFiles" :key="item.tab2">
                <v-row justify="center">
                  <v-col sm="12">
                    <v-card flat>
                      <v-btn @click="downloadFile(item.FilePublicUrl)" color="success">
                        Dosyayı İndir
                        <v-icon right dark> mdi-download</v-icon>
                      </v-btn>
                      <meta charset="UTF-8"/>
                      <title>PDF.js Example</title>
                      <div class="container">
                        <iframe
                            :src="
                            (item.FilePublicUrl.includes('pdf') ||
                              item.FilePublicUrl.includes('PDF')) &&
                            isMobile
                              ? 'https://docs.google.com/gview?url=' +
                                item.FilePublicUrl +
                                '&embedded=true'
                              : item.FilePublicUrl
                          "
                            width="auto"
                            :height="isMobile ? '400' : '800'"
                            frameborder="0"
                            :style="
                            isMobile
                              ? 'overflow-y: auto; overflow-x: scroll'
                              : 'overflow-y: auto; overflow-x: scroll; min-width: 650px'
                          "
                        />
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </b-card-text>
        </b-card>

        <b-card v-if="examSessionSets.length" style="max-width: 360px">
          <v-row justify="center">
            <v-switch v-model="showFile" label="DOSYALARI GÖSTER"></v-switch>
          </v-row>
          <!-- <v-tabs v-model="tab" background-color="indigo" dark grow>
            <v-tab
              v-for="(item, i) in examSessionSets"
              :key="i"
              @change="
                tab = i
                ExamSessionSetId = item.Id
                getAnswersFromApi()
              "
              >{{ item.Name }}</v-tab
            >
          </v-tabs> -->
          <!-- selectbox eklenecek -->
          <b-form-select
              v-model="tab"
              :options="computedExamSessionSets"
              size="sm"
              class="mb-2 mr-sm-2 mb-sm-0"
              @input="selectExamSessionSet()"
          >
            <template #first>
              <b-form-select-option :value="null" disabled
              >-- Oturuma Başlamak İçin Lütfen Seçim Yapınız --
              </b-form-select-option
              >
            </template>
          </b-form-select>
          <b-overlay :show="loading" opacity="0.6">
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in examSessionSets" :key="item.tab">
                <v-row justify="center">
                  <v-col sm="6">
                    <v-card flat></v-card>
                  </v-col>
                </v-row>

                <!-- a b c d e şıklarının gösterildiği bölüm -->

                <b-card-title>
                  {{ item.QuestionCount }} soru
                  <hr/>
                </b-card-title>
                <div v-for="(element, i) in item.QuestionCount" :key="i">
                  <div class="mt-3 d-flex">
                    <div
                        v-if="
                        answers.filter((x) => {
                          return x.QuestionNumber == i + 1
                        }).length
                      "
                        class="text-center flex-grow-1"
                    >
                      {{ i + 1 }})
                      <!-- daha önce verilmiş bir cevap varsa göstermek için -->
                      <b-form-radio-group
                          v-model="
                          answers.filter((x) => {
                            return x.QuestionNumber == i + 1
                          })[0].ExamQuestionOptionId
                        "
                          :options="examQuestionOptions"
                          :button-variant="'outline-primary'"
                          size="md"
                          name="radio-btn-outline"
                          buttons
                          @change="
                          ExamSessionSetId = item.Id
                          saveAnswer(i + 1, $event)
                        "
                          value-field="Id"
                          text-field="Name"
                      ></b-form-radio-group>
                      <v-btn
                          v-if="
                          answers.filter((x) => {
                            return x.QuestionNumber == i + 1
                          })[0].IsFlagged
                        "
                          class="mx-2"
                          fab
                          dark
                          x-small
                          color="error"
                          v-b-tooltip.hover.top="'Eminim olarak işaretle'"
                          @click="
                          ExamSessionSetId = item.Id
                          saveNotSure(
                            i + 1,
                            answers.filter((x) => {
                              return x.QuestionNumber == i + 1
                            })[0].IsFlagged
                          )
                        "
                      >
                        <v-icon>mdi-flag</v-icon>
                      </v-btn>
                      <v-btn
                          v-else
                          class="mx-2"
                          fab
                          dark
                          x-small
                          color="secondary"
                          v-b-tooltip.hover.top="'Emin değilim olarak işaretle'"
                          @click="
                          ExamSessionSetId = item.Id
                          saveNotSure(
                            i + 1,
                            answers.filter((x) => {
                              return x.QuestionNumber == i + 1
                            })[0].IsFlagged
                          )
                        "
                      >
                        <v-icon>mdi-flag-outline</v-icon>
                      </v-btn>
                    </div>
                    <!-- daha önce verilmiş bir cevap yoksa boş abcde şıklarını göstermek için -->
                    <div v-else class="text-center flex-grow-1">
                      {{ i + 1 }})
                      <b-form-radio-group
                          :options="examQuestionOptions"
                          :button-variant="'outline-primary'"
                          size="md"
                          name="radio-btn-outline"
                          buttons
                          @change="
                          ExamSessionSetId = item.Id
                          saveAnswer(i + 1, $event)
                        "
                          value-field="Id"
                          text-field="Name"
                      ></b-form-radio-group>
                      <v-btn class="mx-2" fab dark x-small disabled>
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <b-progress class="mt-3" :max="progressMax" show-value></b-progress>
              </v-tab-item>
            </v-tabs-items>
          </b-overlay>
        </b-card>
        <b-card v-else>
          <b-card-text> OTURUMDA SET YOK</b-card-text>
        </b-card>
      </b-card-group>

      <v-snackbar
          v-if="examSessionStudent != {} && time > 0"
          v-model="snackbar"
          :timeout="timeout"
          :color="snackbarColor"
          rounded="pill"
          top
      >
        <Timer :time="prettyTime"></Timer>
      </v-snackbar>
    </div>
    <div v-if="!examSessionStudent" class="mt-10" align="center">
      <b-alert show variant="danger"><h1>OTURUM KAYDINIZ BULUNAMADI</h1></b-alert>
    </div>
    <v-dialog v-model="dialog" max-width="350px">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-container class="text-center text-break">
            <h2 v-if="examSessionStudent.IsCompleted">
              Oturuma devam etmek istediğinize emin misiniz?
            </h2>
            <h2 v-if="!examSessionStudent.IsCompleted">
              Oturumu bitirmek istediğinize emin misiniz?
            </h2>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialog = false"> HAYIR</v-btn>
          <v-btn color="success" @click="saveExamSessionStudent()"> EVET</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'
import Timer from '../../components/Timer.vue'

export default {
  name: 'oturum-sayfasi',
  components: {
    Timer
  },
  data() {
    return {
      examSession: {},
      examSessionStudent: {},
      examSessionStudentId: 0,
      examSessionSets: [],
      examSessionFiles: [],
      examQuestionOptions: [],
      ExamSessionId: this.$route.params.id,
      tab: null,
      tab2: null,
      answers: [],
      ExamSessionSetId: 0,
      showFile: false,
      loading: false,
      dialog: false,
      isCompleted: false,
      isRunning: false,
      snackbar: false,
      snackbarColor: 'primary',
      timeout: -1, //-1 means no timeout
      time: 0, //duration --- 60 saniye = 1 dakika
      timer: null
    }
  },
  computed: {
    prettyTime() {
      let time = this.time / 60
      let minutes = parseInt(time)
      let secondes = Math.round((time - minutes) * 60)
      return minutes + ':' + secondes
    },
    
    computedExamSessionSets() {
      let arr = this.examSessionSets.map((s) => s.Id)
      return this.examSessionSets.map(function (s) {
        return {
          value: arr.indexOf(s.Id),
          text: s.Name
        }
      })
    },
    
    isMobile() {
      var windowWidth =
          window.screen.width < window.outerWidth ? window.screen.width : window.outerWidth
      return windowWidth < 500
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('Do MMMM YYYY HH:mm') : ''
    }
  },
  mounted() {
    this.getExamSessionFromApi()
  },
  methods: {
    selectExamSessionSet() {
      this.ExamSessionSetId = this.examSessionSets[this.tab].Id
      this.getAnswersFromApi()
    },
    
    endExam() {
      this.dialog = true
      this.isCompleted = true
    },
    
    continueExam() {
      ;(this.dialog = true), (this.isCompleted = false)
    },
    
    dateFormat(d, t) {
      //date, time
      return moment(new Date(d + ' ' + t)).format('YYYY-MM-DDTHH:mm:ss')
    },
    
    saveExamSessionStudent() {
      ApiService.setHeader()
      ApiService.put('api/StudentExam/ExamSessionStudent', {
        Id: this.examSessionStudentId,
        ExamSessionId: this.ExamSessionId,
        IsCompleted: this.isCompleted
      })
      .then(() => {
        this.dialog = false
        this.getExamSessionFromApi()
      })
      .catch(({response}) => {
        ApiService.showError(response)
      })
    },
    
    startTimer() {
      this.isRunning = true
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
      this.snackbar = true
    },
    
    getExamSessionFromApi() {
      ApiService.setHeader()
      ApiService.get('api/StudentExam/ExamSession/' + this.ExamSessionId)
      .then((data) => {
        this.examSession = data.data
        if (this.examSession) this.getExamSessionStudentFromApi()
      })
      .catch(({response}) => {
        ApiService.showError(response)
      })
    },
    
    getExamSessionStudentFromApi() {
      ApiService.setHeader()
      ApiService.get('api/StudentExam/ExamSessionStudent/' + this.ExamSessionId)
      .then((data) => {
        this.examSessionStudent = data.data
        this.examSessionStudentId = this.examSessionStudent.Id
        if (this.examSessionStudent) {
          this.getExamSessionSetFromApi()
          this.getExamQuestionOptionFromApi()
          this.getExamSessionFileFromApi()
        }

        var now = moment()
        var EndDate = moment(this.examSession.EndDate)

        var duration = moment.duration(EndDate.diff(now))
        //(examSession.endDate - datetime.now) aralığı oturum süresinden küçükse oturum süresi bu kadardır
        if (duration.asSeconds() < this.examSession.Duration * 60) {
          this.time = duration.asSeconds()
        }
        //eğer oturum daha önce başlatılmışsa oturum süresi - (now-başl) kadar sürem kalmıştır
        else if (this.examSessionStudent.StartDate) {
          let duration2 = moment(this.examSessionStudent.StartDate)
          duration = moment.duration(now.diff(duration2))
          this.time = this.examSession.Duration * 60 - duration.asSeconds()
          if (this.time < 0) this.time = 0
        }
        //eğer oturuma daha önce başlamadıysam sürem oturum süresi kadardır
        else this.time = this.examSession.Duration * 60

        if (!this.isRunning) this.startTimer()
      })
      .catch(({response}) => {
        ApiService.showError(response)
      })
    },
    getAnswersFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/StudentExam/ExamSessionSetStudentAnswer', {
        sortBy: ['QuestionNumber'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        Query: {
          ExamSessionSetId: this.ExamSessionSetId
        }
      })
      .then((data) => {
        this.answers = data.data.Results
        this.loading = false
      })
      .catch(({response}) => {
        ApiService.showError(response)
      })
    },
    saveAnswer(i, answer) {
      this.loading = true
      ApiService.setHeader()
      ApiService.put('api/StudentExam/ExamSessionSetStudentAnswer', {
        ExamSessionId: this.ExamSessionId,
        ExamSessionSetId: this.ExamSessionSetId,
        ExamSessionStudentId: this.examSessionStudentId, //passing this info from frontend is OK
        QuestionNumber: i,
        ExamQuestionOptionId: answer
      })
      .then(() => {
        this.getAnswersFromApi()
      })
      .catch(({response}) => {
        ApiService.showError(response)
      })
    },
    saveNotSure(i, value) {
      this.loading = true
      ApiService.setHeader()
      ApiService.put('api/StudentExam/TakeTheFlag', {
        ExamSessionId: this.ExamSessionId,
        ExamSessionSetId: this.ExamSessionSetId,
        ExamSessionStudentId: this.examSessionStudentId, //passing this info from frontend is OK
        QuestionNumber: i,
        IsFlagged: !value
      })
      .then(() => {
        this.getAnswersFromApi()
      })
      .catch(({response}) => {
        ApiService.showError(response)
      })
    },
    
    getExamSessionSetFromApi() {
      ApiService.setHeader()
      ApiService.post('api/StudentExam/ExamSessionSet', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        Query: {
          ExamSessionId: this.ExamSessionId
        }
      })
      .then((data) => {
        this.examSessionSets = data.data.Results
        if (this.examSessionSets && this.examSessionSets.length)
          this.ExamSessionSetId = this.examSessionSets[0].Id
        //önce ExamSessionSet gelmesi gerek sonra answers gelebilir
        this.getAnswersFromApi()
      })
      .catch(({response}) => {
        ApiService.showError(response)
      })
    },
    
    getExamSessionFileFromApi() {
      ApiService.setHeader()
      ApiService.post('api/StudentExam/ExamSessionFile', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        Query: {
          ExamSessionId: this.ExamSessionId
        }
      })
      .then((data) => {
        this.examSessionFiles = data.data.Results
      })
      .catch(({response}) => {
        ApiService.showError(response)
      })
    },
    
    downloadFile(url) {
      window.open(url, '_blank')
    },
    
    getExamQuestionOptionFromApi() {
      ApiService.setHeader()
      ApiService.post('api/StudentExam/ExamQuestionOption', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        Query: {
          ExamSessionId: this.ExamSessionId
        }
      })
      .then((data) => {
        data.data.Results.map((x) => {
          if (x.Name === ' ') x.Name = 'Boş'
        })
        this.examQuestionOptions = data.data.Results
      })
      .catch(({response}) => {
        ApiService.showError(response)
      })
    }
  }
}
</script>

<style lang="scss">
.v-snack__wrapper {
  min-width: 100px !important;
  height: 40px !important;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

#app {
  text-align: center;
}

body {
  font-family: sans-serif;
  padding: 0;
  margin: 0;
}

label {
  padding: 10px 0;
  display: inline-block;
}

.timer {
  font-size: 2em;
  margin: 20px;
}
</style>
