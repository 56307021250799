var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-center" }, [
    _c("div", { staticClass: "timer" }, [
      _vm._v(_vm._s(_vm._f("prettify")(_vm.time))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }